import { DocumentScanner, LanguageOutlined } from "@mui/icons-material";
import { Alert, Modal, ModalClose, ModalDialog } from "@mui/joy";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DocumentPanel from "../../../components/translateContent/documentTranslation/DocumentPanel.tsx";
import {
  GlossaryModal,
  type GlossaryModalVariant,
} from "../../../components/translateContent/GlossaryModal";
import type { ChosenGlossary } from "../../../components/translateContent/GlossarySelect.tsx";
import GlossarySelect from "../../../components/translateContent/GlossarySelect.tsx";
import { TextPanel } from "../../../components/translateContent/TextPanel";
import { trpc } from "../../../lib/api/trpc/trpc";
import ToolPage from "../../../components/sidebar/ToolPage.tsx";
import { useTranslateStore } from "../../../lib/context/translateStore.ts";

interface TranslationCategory {
  title: string;
  subtitle: string;
  icon: JSX.Element;
  component: JSX.Element;
  isEnabled: boolean;
}

export interface SelectedLanguages {
  sourceLanguage: string;
  targetLanguage: string;
}

export default function TranslateContent() {
  const [glossaryModalVariant, setGlossaryModalVariant] =
    useState<GlossaryModalVariant>(null);
  const { selectedLanguages, setSelectedLanguages } = useTranslateStore();
  const [modalGlossary, setModalGlossary] = useState<ChosenGlossary | null>(
    null
  );
  const [chosenGlossary, setChosenGlossary] = useState<ChosenGlossary | null>(
    null
  );

  const glossaryMismatch = useMemo(() => {
    if (!chosenGlossary) return false;
    return (
      chosenGlossary?.sourceLang !== selectedLanguages.sourceLanguage ||
      chosenGlossary.targetLang !== selectedLanguages.targetLanguage
    );
  }, [chosenGlossary, selectedLanguages]);

  const { t } = useTranslation();
  const { data: textTranslationEnabled } =
    trpc.tools.translateContent.textTranslator.isEnabled.useQuery();
  const { data: documentTranslatorEnabled } =
    trpc.tools.translateContent.documentTranslator.isEnabled.useQuery();
  const { data: glossaries } =
    trpc.tools.translateContent.glossary.get.useQuery();

  const isGlossaryApplicable =
    selectedLanguages.sourceLanguage === chosenGlossary?.sourceLang &&
    selectedLanguages.targetLanguage === chosenGlossary?.targetLang;

  useEffect(() => {
    const localId = localStorage.getItem("chosenGlossaryId");
    if (!localId || !glossaries) return;

    const savedGlossary = glossaries.find(({ id }) => id === localId);
    if (!savedGlossary) setChosenGlossary(null);
    setChosenGlossary(savedGlossary as ChosenGlossary);
  }, [glossaries]);

  function storeChosenGlossary(glossary: ChosenGlossary | null) {
    localStorage.removeItem("chosenGlossaryId");
    if (glossary) localStorage.setItem("chosenGlossaryId", glossary.id);
    setChosenGlossary(glossary);
  }

  function updateGlossary(value: ChosenGlossary) {
    if (value.id !== chosenGlossary?.id) {
      storeChosenGlossary(value);
    } else {
      storeChosenGlossary(null);
    }
  }

  function showGlossaryModal(
    variant: "create" | "edit" | null,
    glossary: ChosenGlossary | null
  ) {
    setGlossaryModalVariant(variant);
    setModalGlossary(glossary);
  }

  const translationCategories: TranslationCategory[] = useMemo(
    () =>
      [
        {
          title: t("tools.translateText.text"),
          subtitle: "30+ " + t("languages.word"),
          icon: <LanguageOutlined sx={{ fontSize: "30px" }} />,
          component: (
            <>
              {glossaryMismatch && (
                <Alert color="primary" sx={{ mb: 2 }} size="md">
                  {t("tools.translateText.glossary.languagesNotMatching")}
                </Alert>
              )}
              <TextPanel
                glossaryId={
                  isGlossaryApplicable ? chosenGlossary?.id : undefined
                }
                selectedLanguages={selectedLanguages}
                setSelectedLanguages={setSelectedLanguages}
              />
            </>
          ),
          isEnabled: !!textTranslationEnabled,
        },
        {
          title: t("tools.translateText.file"),
          subtitle: "PDF, Word, Excel...",
          icon: <DocumentScanner sx={{ fontSize: "30px" }} />,
          component: (
            <>
              {glossaryMismatch && (
                <Alert color="primary" sx={{ mb: 2 }} size="md">
                  {t("tools.translateText.glossary.languagesNotMatching")}
                </Alert>
              )}
              <DocumentPanel
                glossaryId={
                  isGlossaryApplicable ? chosenGlossary?.id : undefined
                }
                selectedLanguages={selectedLanguages}
                setSelectedLanguages={setSelectedLanguages}
              />
            </>
          ),
          isEnabled: !!documentTranslatorEnabled,
        },
      ].filter((category) => category.isEnabled),
    [
      chosenGlossary?.id,
      documentTranslatorEnabled,
      isGlossaryApplicable,
      selectedLanguages,
      setSelectedLanguages,
      t,
      textTranslationEnabled,
    ]
  );

  return (
    <ToolPage
      title={t("tools.translateText.title")}
      tabs={translationCategories}
      actions={
        <GlossarySelect
          value={chosenGlossary}
          onChange={updateGlossary}
          showModal={showGlossaryModal}
        />
      }
    >
      <Modal
        open={glossaryModalVariant !== null}
        onClose={(_, reason) => {
          reason !== "backdropClick" && setGlossaryModalVariant(null);
        }}
      >
        <ModalDialog
          sx={{
            maxHeight: "90%",
            width: "min(50%, 1000px)",
          }}
        >
          <ModalClose />
          <GlossaryModal
            variant={glossaryModalVariant}
            initGlossary={modalGlossary}
            closeModal={() => {
              setModalGlossary(null);
              setGlossaryModalVariant(null);
            }}
          />
        </ModalDialog>
      </Modal>
    </ToolPage>
  );
}
