import { CloudUpload } from "@mui/icons-material";
import type { DateRangePickerValue } from "@tremor/react";
import {
  Button,
  CircularProgress,
  Divider,
  FormLabel,
  Grid,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Stack,
  Typography,
} from "@mui/joy";
import { useMemo, useState } from "react";
import { trpc } from "../../../../../../lib/api/trpc/trpc.ts";
import { MeetingCard } from "./MeetingCard.tsx";
import { Uploader } from "./Uploader.tsx";
import { useNavigate, useParams } from "../../../../../../router.ts";
import { FormControl } from "@mui/base";
import { useTranslation } from "../../../../../../lib/i18n.ts";
import { de, enUS } from "date-fns/locale";
import { differenceInDays, format, isToday, isYesterday } from "date-fns";
import LocalizedDateRangePicker from "../../../../../../components/input/LocalizedDateRangePicker.tsx";
import { type Meeting } from "../../../../../../../../backend/src/api/tools/meetingTools/recordingTranscriber/recordingTranscriberTypes.ts";

export default function MeetingTranscriber() {
  const [dateRange, setDateRange] = useState<DateRangePickerValue | undefined>(
    undefined
  );
  const [showUploadModal, setShowUploadModal] = useState(false);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { organizationId } = useParams("/:organizationId");

  const { data: meetings, isLoading } =
    trpc.tools.meetingTools.recordingTranscriber.getAll.useQuery(
      dateRange?.from && dateRange?.to
        ? {
            startDate: dateRange.from.toISOString(),
            endDate: dateRange.to.toISOString(),
          }
        : undefined,
      {
        refetchInterval: 5000,
      }
    );

  const sortedMeetings = useMemo(() => {
    if (!meetings) return;

    const today: Meeting[] = [];
    const yesterday: Meeting[] = [];
    const prevWeek: Meeting[] = [];
    const prevMonth: Meeting[] = [];
    const older: Record<string, Meeting[]> = {};

    for (const meeting of meetings) {
      const { date } = meeting;
      const meetingDate = new Date(date);

      if (isToday(meetingDate)) {
        today.push(meeting);
        continue;
      }

      if (isYesterday(meetingDate)) {
        yesterday.push(meeting);
        continue;
      }

      const daysAgo = differenceInDays(new Date(), meetingDate);

      if (daysAgo <= 7) {
        prevWeek.push(meeting);
      } else if (daysAgo <= 30) {
        prevMonth.push(meeting);
      } else {
        const month = format(meetingDate, "MMMM yyyy", {
          locale: i18n.language === "de" ? de : enUS,
        });
        if (!older[month]) {
          older[month] = [];
        }
        older[month].push(meeting);
      }
    }

    return {
      today: {
        title: t("tools.meetingTools.recordingTranscriber.dateRanges.today"),
        items: today,
      },
      yesterday: {
        title: t(
          "tools.meetingTools.recordingTranscriber.dateRanges.yesterday"
        ),
        items: yesterday,
      },
      prevWeek: {
        title: t("tools.meetingTools.recordingTranscriber.dateRanges.prevWeek"),
        items: prevWeek,
      },
      prevMonth: {
        title: t(
          "tools.meetingTools.recordingTranscriber.dateRanges.prevMonth"
        ),
        items: prevMonth,
      },
      ...Object.entries(older).reduce(
        (acc, [key, items]) => ({
          ...acc,
          [key]: {
            title: key,
            items,
          },
        }),
        {}
      ),
    };
  }, [meetings, t, i18n.language]);

  const handleNavigation = (meetingId: string) => {
    navigate(
      `/:organizationId/tools/meetingTools/recordingTranscriber/:meetingId`,
      {
        params: { organizationId, meetingId },
      }
    );
  };

  return (
    <Stack gap={2}>
      {meetings?.length !== 0 && (
        <div className="flex items-end justify-between">
          <FormControl>
            <FormLabel sx={{ pb: 0.5 }}>
              {t("tools.meetingTools.recordingTranscriber.filterByDate")}
            </FormLabel>
            <LocalizedDateRangePicker
              disabled={isLoading}
              value={dateRange}
              onValueChange={setDateRange}
            />
          </FormControl>
          <Button
            sx={{ whiteSpace: "nowrap" }}
            startDecorator={<CloudUpload />}
            onClick={() => setShowUploadModal(true)}
          >
            {t("tools.meetingTools.recordingTranscriber.upload")}
          </Button>
        </div>
      )}
      {!isLoading &&
        sortedMeetings &&
        Object.entries(sortedMeetings).map(([key, { title, items }]) => {
          if (items.length === 0) return;
          return (
            <Stack key={key} gap={2} pb={1}>
              <Typography level="title-md">
                {title}
                <Divider />
              </Typography>
              <Grid container spacing={2}>
                {items?.map((meeting) => (
                  <Grid key={meeting.id} xs={12} sm={6} xl={3}>
                    <MeetingCard
                      meeting={meeting}
                      onClick={() => handleNavigation(meeting.id)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          );
        })}

      {!isLoading && meetings?.length === 0 && (
        <div className="flex flex-col items-center gap-4 py-24">
          <Typography level="body-lg">
            {t("tools.meetingTools.recordingTranscriber.noMeetings")}
          </Typography>
          <Button
            startDecorator={<CloudUpload />}
            onClick={() => setShowUploadModal(true)}
          >
            {t("tools.meetingTools.recordingTranscriber.upload")}
          </Button>
        </div>
      )}
      {isLoading && <CircularProgress />}

      <Modal
        open={showUploadModal}
        onClose={(
          _event: React.MouseEvent<HTMLButtonElement>,
          reason: string
        ) => {
          if (reason === "closeClick") {
            setShowUploadModal(false);
          }
        }}
      >
        <ModalOverflow>
          <ModalDialog>
            <ModalClose />
            <Uploader onUpload={() => setShowUploadModal(false)} />
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </Stack>
  );
}
