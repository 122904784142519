import { Sheet, Typography } from "@mui/joy";
import { type ToolTabProps, ToolTabs } from "../tools/ToolTabs";

/**
 * A layout component that displays a title, subtitle, action button and a list of tabs for the tools.
 * If there is exactly one tab, the tab title and subtitle override the main title and subtitle of the ToolPage.
 * @param title - The main title.
 * @param subtitle - The main subtitle.
 * @param actions - An optional action button to be displayed in the top right corner of the ToolPage.
 * @param tabs - An optional array of tabs with content to be displayed in the ToolPage.
 * @param children - Main content of the ToolPage. Not necessary if tabs are provided.
 */
export default function ToolPage({
  title,
  subtitle,
  actions,
  tabs,
  children,
}: {
  title: string;
  subtitle?: string;
  actions?: React.ReactNode;
  tabs?: ToolTabProps[];
  children?: React.ReactNode;
}) {
  const singleTab = tabs?.length === 1; // The page has exactly one enabled tab

  const renderTabs = () => {
    if (singleTab) {
      return tabs[0].component;
    } else if (tabs) {
      return <ToolTabs tabs={tabs} />;
    }
    return null;
  };

  const pageTitle = singleTab ? tabs[0].title : title;
  const pageSubtitle = singleTab ? tabs[0].subtitle : subtitle;
  return (
    <Sheet variant="soft" sx={{ minHeight: "100vh" }}>
      <div className="flex max-w-screen-2xl flex-col justify-center gap-6 p-10">
        <div className="flex flex-wrap items-center justify-between gap-y-4">
          <div>
            <Typography level="h1">{pageTitle}</Typography>
            {pageSubtitle && (
              <Typography level="body-md">{pageSubtitle}</Typography>
            )}
          </div>
          {actions}
        </div>
        {renderTabs()}
        {children}
      </div>
    </Sheet>
  );
}
