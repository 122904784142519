import { AudioFile, Close, CloudUpload, Info } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  Textarea,
  Typography,
  Alert,
} from "@mui/joy";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { trpc } from "../../../../../../lib/api/trpc/trpc.ts";
import { readableFileSize } from "../../../../../../lib/util.ts";
import { Trans, useTranslation } from "react-i18next";
import { audioMimeType } from "../../../../../../../../backend/src/constants/mime.ts";
import * as Sentry from "@sentry/react";

// Maximum duration in minutes (4h 15min)
const MAX_DURATION_MINUTES = 255;
const CREDITS_PER_HOUR = 100;

const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  const pad = (num: number) => num.toString().padStart(2, "0");

  if (hours > 0) {
    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  }
  return `${pad(minutes)}:${pad(remainingSeconds)}`;
};

export function Uploader({ onUpload }: { onUpload: () => void }) {
  const { mutateAsync: createAndUpload } =
    trpc.tools.meetingTools.recordingTranscriber.createAndUpload.useMutation();

  const { mutateAsync: process } =
    trpc.tools.meetingTools.recordingTranscriber.startTranscription.useMutation();

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [audioDuration, setAudioDuration] = useState<number | null>(null);
  const [durationError, setDurationError] = useState<string | null>(null);

  const { t } = useTranslation();

  // TODO convert to formik
  const [displayName, setDisplayName] = useState<string | null>(null);
  const [languageCode, setLanguageCode] = useState("de");
  const [vocabulary, setVocabulary] = useState(
    localStorage.getItem("transcriptVocabulary") ?? ""
  );

  const [uploading, setUploading] = useState<boolean>(false);

  const checkAudioDuration = (file: File): Promise<number> => {
    return new Promise((resolve, reject) => {
      const audio = document.createElement("audio");
      audio.preload = "metadata";

      audio.addEventListener("loadedmetadata", () => {
        resolve(audio.duration);
        URL.revokeObjectURL(audio.src); // Clean up
      });

      audio.addEventListener("error", () => {
        URL.revokeObjectURL(audio.src);
        reject(new Error("Error loading audio file"));
      });

      audio.src = URL.createObjectURL(file);
    });
  };

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      return;
    }

    // Clear previous states
    setDurationError(null);
    setAudioDuration(null);

    if (!displayName) {
      setDisplayName(file.name);
    }
    setUploadedFile(file);

    try {
      const durationInSeconds = await checkAudioDuration(file);
      const durationInMinutes = durationInSeconds / 60;
      setAudioDuration(durationInSeconds);

      if (durationInMinutes > MAX_DURATION_MINUTES) {
        setDurationError(
          t("tools.meetingTools.recordingTranscriber.audioTooLong", {
            maxLength: "4h 15min",
          })
        );
        return;
      }
    } catch (error) {
      console.error("Error checking audio duration:", error);
      Sentry.captureException(error, {
        tags: {
          component: "Uploader",
          action: "checkAudioDuration",
        },
        extra: {
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
        },
      });
      toast.warn(
        t("tools.meetingTools.recordingTranscriber.errorCheckingDuration")
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      onDrop,
      disabled: uploading,
      accept: audioMimeType,
      multiple: false,
    });

  const createMeeting = async () => {
    if (!uploadedFile || !displayName) {
      return;
    }
    setUploading(true);
    const { uploadUrl, id: meetingId } = await createAndUpload({
      displayName,
      cleanUpAudio: false,
      languageCode,
      numSpeakers: 2,
      advancedDiarization: true,
      vocabulary,
    });
    await fetch(uploadUrl, {
      method: "PUT",
      body: uploadedFile,
    });
    toast.success(t("tools.meetingTools.recordingTranscriber.fileUploaded"));
    void process({ meetingId });
    onUpload();
  };

  let dropzoneText = t("tools.meetingTools.recordingTranscriber.dragToUpload");
  if (isDragActive) {
    if (!isDragAccept) {
      dropzoneText = t(
        "tools.meetingTools.recordingTranscriber.mimeNotSupported"
      );
    } else {
      dropzoneText = t("tools.meetingTools.recordingTranscriber.dropFile");
    }
  }

  return (
    <div className="flex flex-col gap-8">
      <Typography level="title-lg">
        {t("tools.meetingTools.recordingTranscriber.newMeeting")}
      </Typography>

      <FormControl required disabled={uploading}>
        <FormLabel>
          {t("tools.meetingTools.recordingTranscriber.nameOfMeeting")}
        </FormLabel>
        <Input
          type="text"
          required
          value={displayName ?? undefined}
          onChange={(e) => {
            setDisplayName(e.target.value ?? null);
          }}
        />
      </FormControl>
      <FormControl required disabled={uploading} error={durationError != null}>
        <FormLabel>
          {t("tools.meetingTools.recordingTranscriber.recording")}
        </FormLabel>
        <Box
          sx={{
            border: "2px dashed",
            borderColor: "neutral.outlinedBorder",
            borderRadius: "sm",
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            height: 120,
            cursor: "pointer",
            "&:hover": {
              bgcolor: "background.level1",
            },
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          {uploadedFile ? (
            <Card
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
                p: 1,
              }}
            >
              <AudioFile color="primary" sx={{ fontSize: 40 }} />
              <Box sx={{ flex: 1 }}>
                <Typography>{uploadedFile.name}</Typography>
                <Typography level="body-sm" color="neutral">
                  {readableFileSize(uploadedFile.size)}
                  {audioDuration && ` • ${formatDuration(audioDuration)}`}
                </Typography>
              </Box>
              <IconButton
                disabled={uploading}
                size="sm"
                variant="plain"
                color="neutral"
                onClick={(e) => {
                  e.stopPropagation();
                  setUploadedFile(null);
                  setAudioDuration(null);
                  setDurationError(null);
                }}
              >
                <Close />
              </IconButton>
            </Card>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              <CloudUpload sx={{ fontSize: 40 }} />
              <Typography textAlign="center">{dropzoneText}</Typography>
            </Box>
          )}
        </Box>
        <FormHelperText color={durationError ? "danger" : undefined}>
          {durationError ||
            t("tools.meetingTools.recordingTranscriber.supportedFormats")}
        </FormHelperText>
      </FormControl>

      <FormControl sx={{ flexGrow: 1 }} disabled={uploading}>
        <FormLabel>
          {t("tools.meetingTools.recordingTranscriber.recordingLanguage")}
        </FormLabel>
        <Select
          value={languageCode}
          onChange={(_e, newValue) => setLanguageCode(newValue ?? "de")}
        >
          <Option value="de">{t("languages.de")}</Option>
          <Option value="en">{t("languages.en")}</Option>
          <Option value="fr">{t("languages.fr")}</Option>
          <Option value="it">{t("languages.it")}</Option>
          <Option value="es">{t("languages.es")}</Option>
        </Select>
      </FormControl>

      <FormControl disabled={uploading}>
        <FormLabel>
          {t("tools.meetingTools.recordingTranscriber.vocabulary.title")}
        </FormLabel>
        <Textarea
          minRows={3}
          maxRows={10}
          value={vocabulary}
          placeholder="meinGPT, Jour Fixe, ..."
          onChange={(e) => {
            setVocabulary(e.target.value);
            localStorage.setItem("transcriptVocabulary", e.target.value);
          }}
        />
        <FormHelperText>
          {t("tools.meetingTools.recordingTranscriber.vocabulary.subtitle")}
        </FormHelperText>
      </FormControl>

      <Alert startDecorator={<Info />}>
        <Trans>
          <Typography level="body-sm">
            {audioDuration
              ? t(
                  "tools.meetingTools.recordingTranscriber.infoProcessingWithDuration",
                  {
                    credits: Math.ceil(
                      (audioDuration / 3600) * CREDITS_PER_HOUR
                    ),
                    duration: formatDuration(audioDuration),
                  }
                )
              : t("tools.meetingTools.recordingTranscriber.infoProcessing", {
                  credits: CREDITS_PER_HOUR,
                })}
          </Typography>
        </Trans>
      </Alert>

      <Button
        onClick={createMeeting}
        disabled={
          !uploadedFile || !displayName || uploading || durationError != null
        }
        loading={uploading}
      >
        {t("tools.meetingTools.recordingTranscriber.uploadAndTranscript")}
      </Button>
    </div>
  );
}
