import { Email, Warning } from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import wordIcon from "../../../../../assets/word_icon.png";
import { DocumentDropzone } from "../../../../../components/chat/attachments/DocumentDropzone.tsx";
import { MarkdownRenderer } from "../../../../../components/chat/MarkdownRenderer.tsx";
import { useSmoothTypingText } from "../../../../../components/chat/useSmoothTypingText.ts";
import { SegmentDisplay } from "../../../../../components/input/SegmentDisplay.tsx";
import { trpc } from "../../../../../lib/api/trpc/trpc.ts";
import { useDownloadAsDoc } from "../../../../../lib/documentHooks.ts";
import { useTranslation } from "../../../../../lib/i18n.ts";
import {
  TRANSCRIBE_ERROR,
  TRANSCRIBE_BUTTON,
} from "../../../../../lib/testIds.ts";

type TranscriptLength = "short" | "appropriate" | "comprehensive";

const LanguageCodeMap: Record<string, string> = {
  en: "english",
  de: "german",
  fr: "french",
  es: "spanish",
  it: "italian",
};

const SummaryComponents = {
  executiveSummary: {
    label: "Executive Summary",
    value: "executiveSummary",
  },
  participants: {
    label: "Participants",
    value: "participants",
  },
  agenda: {
    label: "Agenda",
    value: "agenda",
  },
  actionItems: {
    label: "Action Items",
    value: "actionItems",
  },
};

export default function TranscriptSummarizer() {
  const { t, i18n } = useTranslation();

  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [selectedLength, setSelectedLength] =
    useState<TranscriptLength>("appropriate");
  const [components, setComponents] = useState<
    Record<keyof typeof SummaryComponents, boolean>
  >({
    executiveSummary: true,
    participants: true,
    agenda: true,
    actionItems: true,
  });
  const htmlRef = useRef<HTMLDivElement>(null);
  const [response, setResponse] = useState<string>("");
  const [generating, setGenerating] = useState<boolean>(false);
  const [summarizeMeetingError, setSummarizeMeetingError] = useState<
    string | null
  >(null);

  const trpcUtils = trpc.useUtils();

  const smoothedResponse = useSmoothTypingText(response);

  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    LanguageCodeMap[i18n.language.split("-")[0]] ?? "english"
  );

  async function summarize() {
    if (!uploadedFiles.length) {
      toast.error(t("modals.dataConnectors.fileUpload.noFiles"));
      return;
    }
    if (generating) return;

    setGenerating(true);
    setSummarizeMeetingError(null);

    try {
      const stream =
        await trpcUtils.tools.meetingTools.transcriptSummarizer.summarize.fetch(
          {
            documentId: uploadedFiles[0],
            length: selectedLength,
            components,
            language: selectedLanguage,
          }
        );

      for await (const chunk of stream) {
        setResponse(chunk.response);
      }
    } catch (e) {
      console.log("caught an error", e);
      setResponse("");
      setSummarizeMeetingError(
        (e instanceof Error && e.message) ||
          t("tools.meetingTools.transcriptSummarizer.unknownError")
      );
    } finally {
      setGenerating(false);
    }
  }

  const { downloadDocument } = useDownloadAsDoc({
    markdown: response,
    filename: "meeting_summary",
  });

  return (
    <Stack gap={2}>
      <Card>
        <div className="mb-2 flex flex-col gap-6 lg:flex-row">
          <div className="flex-1">
            <DocumentDropzone
              allowMultiple={false}
              documentIds={uploadedFiles}
              setDocumentIds={setUploadedFiles}
              customMime={{
                "text/vtt": [".vtt"],
                "application/msword": [".docx", ".doc"],
                "application/pdf": [".pdf"],
                "text/plain": [".txt"],
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>

          <div className="flex-1">
            <Typography level="title-md" mb={1}>
              {t(
                "tools.meetingTools.transcriptSummarizer.settings.language.title"
              )}
            </Typography>
            <Select
              value={selectedLanguage}
              onChange={(_, value) => setSelectedLanguage(value as string)}
              size="sm"
              sx={{ mb: 2 }}
            >
              {Object.entries(LanguageCodeMap).map(([key, language]) => (
                <Option key={key} value={language}>
                  {t(
                    `tools.meetingTools.transcriptSummarizer.settings.language.${language}`
                  )}
                </Option>
              ))}
            </Select>
            <Typography level="title-md" mb={1}>
              {t(
                "tools.meetingTools.transcriptSummarizer.settings.length.title"
              )}
            </Typography>
            <SegmentDisplay
              size="sm"
              options={
                [
                  {
                    label: t(
                      "tools.meetingTools.transcriptSummarizer.settings.length.short"
                    ),
                    value: "short",
                  },
                  {
                    label: t(
                      "tools.meetingTools.transcriptSummarizer.settings.length.appropriate"
                    ),
                    value: "appropriate",
                  },
                  {
                    label: t(
                      "tools.meetingTools.transcriptSummarizer.settings.length.comprehensive"
                    ),
                    value: "comprehensive",
                  },
                ] as const
              }
              value={selectedLength}
              setValue={(value: TranscriptLength) => setSelectedLength(value)}
            />
            <Typography level="title-md" mt={2} mb={1}>
              {t(
                "tools.meetingTools.transcriptSummarizer.settings.components.title"
              )}
            </Typography>
            <div className="flex flex-col gap-2">
              {Object.entries(SummaryComponents).map(([key, { value }]) => (
                <Checkbox
                  key={key}
                  checked={components[value as keyof typeof SummaryComponents]}
                  onChange={() => {
                    setComponents((prev) => ({
                      ...prev,
                      [value]: !prev[value as keyof typeof SummaryComponents],
                    }));
                  }}
                  label={t(
                    `tools.meetingTools.transcriptSummarizer.settings.components.${value}`
                  )}
                />
              ))}
            </div>
          </div>
        </div>
        <Button
          data-testid={TRANSCRIBE_BUTTON}
          sx={{ alignSelf: "start" }}
          onClick={summarize}
          loading={generating}
          disabled={!uploadedFiles.length}
        >
          {response || summarizeMeetingError ? t("regenerate") : t("summarize")}
        </Button>
      </Card>
      {summarizeMeetingError && (
        <Alert
          color="danger"
          startDecorator={<Warning />}
          className="w-full"
          sx={{ alignItems: "flex-start" }}
          data-testid={TRANSCRIBE_ERROR}
        >
          <div>
            <div>{t("tools.meetingTools.transcriptSummarizer.error")}</div>
            <Typography color="danger" level="body-sm">
              {summarizeMeetingError}
            </Typography>
            <Typography level="body-sm" mt={1}>
              {t("tools.meetingTools.transcriptSummarizer.tryAgain")}
            </Typography>
          </div>
        </Alert>
      )}

      {response && (
        <>
          <div ref={htmlRef}>
            <Card>
              <MarkdownRenderer content={smoothedResponse} />
            </Card>
          </div>
          <div className="flex flex-row items-center gap-2">
            <Button
              onClick={() => {
                window.location.href =
                  "mailto:?subject=Meetingzusammenfassung&body=" +
                  encodeURI(htmlRef.current?.innerText || "");
              }}
              disabled={generating}
            >
              <Email sx={{ mr: 1 }} />
              {t("tools.meetingTools.transcriptSummarizer.sendAsEmail")}
            </Button>

            <Button
              disabled={generating}
              onClick={() => downloadDocument("docx")}
              startDecorator={
                <img src={wordIcon} alt="Word Icon" className="h-5 w-5" />
              }
            >
              {t("downloadAsWord")}
            </Button>
          </div>
        </>
      )}
    </Stack>
  );
}
