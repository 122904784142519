import { GraphicEq, TextSnippet } from "@mui/icons-material";
import TranscriptSummarizer from "./_components/TranscriptSummarizer";
import { useMemo } from "react";
import { useTranslation } from "../../../../lib/i18n";
import ToolPage from "../../../../components/sidebar/ToolPage";
import { trpc } from "../../../../lib/api/trpc/trpc";
import RecordingTranscriber from "./_components/recordingTranscriber/RecordingTranscriber";

export default function MeetingTools() {
  const { t } = useTranslation();
  const { data: recordingTranscriberEnabled } =
    trpc.tools.meetingTools.recordingTranscriber.isEnabled.useQuery();
  const { data: transcriptSummarizerEnabled } =
    trpc.tools.meetingTools.transcriptSummarizer.isEnabled.useQuery();

  const summarizeTools = useMemo(
    () =>
      [
        {
          title: t("tools.meetingTools.recordingTranscriber.title"),
          subtitle: t("tools.meetingTools.recordingTranscriber.subtitle"),
          icon: <GraphicEq />,
          component: <RecordingTranscriber />,
          isEnabled: !!recordingTranscriberEnabled,
        },
        {
          title: t("tools.meetingTools.transcriptSummarizer.title"),
          subtitle: t("tools.meetingTools.transcriptSummarizer.subtitle"),
          icon: <TextSnippet />,
          component: <TranscriptSummarizer />,
          isEnabled: !!transcriptSummarizerEnabled,
        },
      ].filter((category) => category.isEnabled),
    [recordingTranscriberEnabled, transcriptSummarizerEnabled, t]
  );

  return (
    <ToolPage
      title={t("tools.meetingTools.title")}
      subtitle={t("tools.meetingTools.subtitle")}
      tabs={summarizeTools}
    />
  );
}
