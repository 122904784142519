import { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import TabPanel from "@mui/joy/TabPanel";
import { TabWithIcon } from "./TabWithIcon";

export type ToolTabProps = {
  title: string;
  subtitle?: string;
  icon?: React.ReactNode;
  component: React.ReactNode;
};

/**
 * @param tabs - An array of tabs with content to be displayed.
 * @param helperText - An optional helper text to be displayed in the bottom of the tabs.
 */
export function ToolTabs({
  tabs,
  helperText,
}: {
  tabs: ToolTabProps[];
  helperText?: JSX.Element | boolean | null;
}) {
  return (
    <Tabs aria-label="tabs" defaultValue={0} sx={{ bgcolor: "transparent" }}>
      <div className="flex justify-between pb-8">
        <TabList
          disableUnderline
          size="sm"
          sx={{
            p: 0,
            borderRadius: "lg",
            width: "fit-content",
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: "sm",
              bgcolor: "background.surface",
            },
          }}
        >
          <div className="flex gap-4">
            {tabs.map((tool, index) => (
              <TabWithIcon
                key={tool.title}
                value={index}
                icon={tool.icon}
                title={tool.title}
                subtitle={tool.subtitle}
              />
            ))}
          </div>
        </TabList>
      </div>
      {helperText}
      {tabs.map((tab, index) => {
        return (
          <TabPanel key={tab.title} value={index} sx={{ p: 0 }}>
            {tab.component}
          </TabPanel>
        );
      })}
    </Tabs>
  );
}
