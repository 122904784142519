import { Tab } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";

export type TabWithIconProps = {
  value: number;
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
};

export function TabWithIcon({
  value,
  icon,
  title,
  subtitle,
}: TabWithIconProps) {
  return (
    <Tab
      disableIndicator
      value={value}
      sx={{
        border: "1px solid lightgray",
        px: 2,
        py: 1,
      }}
    >
      <Stack direction="row" alignItems="center" gap={1.5}>
        {icon}
        <Stack>
          <Typography level="title-md">{title}</Typography>
          {subtitle && <Typography level="body-sm">{subtitle}</Typography>}
        </Stack>
      </Stack>
    </Tab>
  );
}
